import React from 'react';

const SvgComponent: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 60 60"
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <path
          d="M59,54.878h-1v-14H46v14h-3v-23H31v23h-3v-31H16v31h-3v-39H1v39c-0.552,0-1,0.447-1,1s0.448,1,1,1h12h3h12h3h12h3h12h1
		c0.552,0,1-0.447,1-1S59.552,54.878,59,54.878z M3,54.878v-37h8v37H3z M18,54.878v-29h8v29H18z M33,54.878v-21h8v21H33z M48,54.878
		v-12h8v12H48z"
        />
        <path
          d="M11.781,4.989l36.269,20.94l-5.457,0.529c-0.549,0.053-0.952,0.542-0.898,1.092c0.05,0.517,0.485,0.903,0.994,0.903
		c0.032,0,0.064-0.002,0.098-0.005l8.563-0.83c0.016-0.001,0.029-0.011,0.044-0.014c0.079-0.011,0.155-0.032,0.23-0.062
		c0.014-0.006,0.029-0.003,0.043-0.009c0.01-0.005,0.016-0.014,0.026-0.019c0.003-0.001,0.006-0.002,0.009-0.004
		c0.011-0.006,0.024-0.008,0.035-0.014c0.087-0.048,0.166-0.111,0.236-0.185c0.011-0.012,0.021-0.024,0.032-0.037
		c0.018-0.02,0.03-0.042,0.046-0.064c0.022-0.03,0.05-0.056,0.069-0.089c0.014-0.024,0.014-0.051,0.026-0.076
		c0.016-0.034,0.037-0.065,0.049-0.101c0.013-0.037,0.014-0.075,0.023-0.114c0.012-0.055,0.021-0.107,0.024-0.162
		c0.002-0.038,0.007-0.073,0.004-0.111c-0.001-0.012,0.005-0.022,0.003-0.034c-0.003-0.028-0.018-0.05-0.023-0.077
		c-0.01-0.056-0.024-0.109-0.044-0.162c-0.009-0.026-0.007-0.052-0.018-0.078l-3.563-7.83c-0.229-0.503-0.822-0.728-1.324-0.496
		c-0.503,0.229-0.725,0.821-0.496,1.324l2.27,4.99L12.781,3.256c-0.48-0.276-1.09-0.111-1.366,0.366
		C11.139,4.101,11.303,4.713,11.781,4.989z"
        />
      </g>
    </svg>
  );
};

export default SvgComponent;
